<template>
  <div class="hello">
    <a class="po" href="https://сайтвсем.рф" target="_blank" rel="noopener"><h1>подология21.рф</h1></a>
    <p>
      10 000 руб -
      <a href="https://сайтвсем.рф" target="_blank" rel="noopener">связаться с владельцем домена</a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.po {
  text-decoration: none;
}
a {
  color: red;
}
</style>
